<!--
 * @Descripttion: 直播课
 * @version: 1.1.2pc
 * @Author: ivan
 * @Date: 2019-11-19 10:11:37
 * @LastEditors: sueRimn
 * @LastEditTime: 2019-12-05 20:50:59
 -->
<template>
  <div class="new-course-wrap">
    <router-view></router-view>
  </div>
</template>
<script>
import bg from '@/mixin/background'

export default {
  mixins: [bg]
}
</script>
<style lang="scss" scoped>
.new-course-wrap {
  padding: 20px;
}
</style>
